import React, { useEffect, useMemo, useState } from 'react'
import bem from '../../../utils/bem'
import { withPage } from '../../page/Page'
import '../../../styles/create-campaign-page.scss'
import { useHistory } from 'react-router-dom'
import BusinessDataForm from './Step0-Buisness/BusinessDataForm'
import { Card, Spinner } from 'react-bootstrap'
import CampaignDataForm from './Step1-Campaign/CampaignDataForm'
import useCampaignData from '../../../stores/campaignData'
import DataUpload from './Step3-Upload/DataUpload'
import useResponsive from '../../../utils/useResponsive'
import moment from 'moment'
import AdFormatSummary from './Step2-ADformats/ADFormatSummary'

const cn = bem('create-campaign-page')

const STEPS = [
	{ label: 'Данные о бизнесе', Component: BusinessDataForm },
	{
		label: 'Данные о рекламной кампании',
		Component: CampaignDataForm,
	},
	{ label: 'Форматы рекламы', Component: AdFormatSummary },
	{ label: 'Загрузка материалов', Component: DataUpload },
]

export function CreateCampaignPage() {
	const history = useHistory()
	const [campaign] = useCampaignData()
	const [step, setStep] = useState(1)

	function changeStep(step) {
		if (step === 0) return

		setStep(step)
	}

	const { Component, label } = useMemo(() => STEPS[step], [step])
	const [initialValues, setInitialValues] = useState<null | {}>(null)
	const [companyId, setCompanyId] = useState(() => {
		if (campaign?.id) return campaign?.company?.id
		return null
	})
	const { isMobile } = useResponsive()
	const firstAvailableStartDate = useMemo(() => {
		const today = moment()
		if (today.date() < 20) {
			today.add(1, 'month').date(1)
		} else {
			today.add(1, 'month').date(15)
		}
		return today
	}, [])
	const CheckCampaignStartDate = useMemo(() => {
		if (!campaign?.start_date) return true
		const start = moment(campaign.start_date, 'YYYY-MM-DD')
		if (
			![1, 15].includes(start.date()) ||
			firstAvailableStartDate.diff(start, 'd') > 0
		) {
			return false
		}
		return true
	}, [campaign.start_date, firstAvailableStartDate])
	const availableStep = useMemo(() => {
		if (!CheckCampaignStartDate) return 1
		return campaign.is_budget_confirmed ? 3 : campaign.id ? 2 : 0
	}, [campaign, CheckCampaignStartDate])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [step])

	return (
		<div className={cn()}>
			<div className={cn('body')}>
				{step !== 2 && step !== 3 ? (
					<Card className={cn('form')}>
						<h2
							className={cn('subtitle')}
							style={{ marginBottom: '8px' }}
						>
							{label}
						</h2>
						<div
							className={cn('separator')}
							style={{
								margin: 0,
								padding: 0,
								marginBottom: '24px',
							}}
						/>

						<Component
							cn={cn}
							companyId={campaign?.company?.id || companyId}
							onSetCompanyId={async (data) => {
								const { id, name } = data
								setCompanyId(id)
								setInitialValues({ name })
								setStep(1)
							}}
							onNextStep={() => setStep((state) => ++state)}
							onBack={() => setStep((state) => --state)}
							setStep={setStep}
							initialValues={initialValues}
						/>
					</Card>
				) : (
					<Component
						cn={cn}
						companyId={campaign?.company?.id || companyId}
						onSetCompanyId={async (data) => {
							const { id, name } = data
							setCompanyId(id)
							setInitialValues({ name })
							setStep(1)
						}}
						onNextStep={() => setStep((state) => ++state)}
						onBack={() => setStep((state) => --state)}
						setStep={setStep}
						initialValues={initialValues}
					/>
				)}

				<Stepper
					step={step}
					avaliableStep={availableStep}
					onStepClick={changeStep}
					isMobile={isMobile}
				/>
			</div>
		</div>
	)
}

function Stepper({ step, avaliableStep, onStepClick, isMobile }) {
	return (
		<ol className={cn('stepper')} id={'create-campaign-stepper'}>
			<div className={cn('stepper-block')}>
				{STEPS.filter((s, i) => i <= step).map(({ label }, i) => (
					<li
						key={label}
						className={cn('step', {
							active: step === i,
							completed: step > i,
							avaliable: true,
						})}
						onClick={
							i <= avaliableStep ? () => onStepClick(i) : () => {}
						}
					>
						<div className={cn('step-num')}>
							{step > i ? <i className="bi bi-check2" /> : i + 1}
						</div>
						<div className={cn('step-label')}>
							{!isMobile ? label : ''}
						</div>
					</li>
				))}
			</div>
			<div className={cn('stepper-block')}>
				{STEPS.filter((s, i) => i > step).map(({ label }, i) => (
					<li
						key={label}
						className={cn('step', {
							avaliable: i + step + 1 <= avaliableStep,
						})}
						onClick={
							i + step + 1 <= avaliableStep
								? () => onStepClick(i + step + 1)
								: () => {}
						}
					>
						<div className={cn('step-num')}>{i + 2 + step}</div>
						<div className={cn('step-label')}>{label}</div>
					</li>
				))}
			</div>
		</ol>
	)
}

export default withPage({ noSidebar: false, isProvider: false })(
	CreateCampaignPage
)
