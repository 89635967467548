import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Formik } from 'formik'
import useResponsive from '../../../../../utils/useResponsive'
import { Form, InputGroup } from 'react-bootstrap'
import { TVIcon } from './TVSelector'
import AdMarketMultiplyDropdown from '../../../../UtilityComponents/ADMarketMultiplyDropdown/ADMarketMultiplyDropdown'
import { BASE_URL } from '../../../../../constants/api'
import { authFetch } from '../../../../../utils/authFetch'
import useCampaignData from '../../../../../stores/campaignData'
import useDebounce from '../../../../../utils/useDebounce'
import { spreadArray } from '../../../../_provider/Mediaformats/MediaInfo'
import InputWithEndText from '../../../../UtilityComponents/InputWithEndText'

const url_sections = BASE_URL + '/tv_preroll/sections/'
export type ChannelSectionType = {
	id: number
	name: string
	channels_count: number
	channels: {
		id: number
		name: string
	}[]
}

export const fetchAdstreamInfo = async () =>
	// eslint-disable-next-line compat/compat
	new Promise<{ sections: ChannelSectionType[] }>(async (res, rej) => {
		try {
			const sections: ChannelSectionType[] = await authFetch({
				url: url_sections,
				method: 'GET',
			})
			// eslint-disable-next-line compat/compat
			Promise.all([sections]).then(() => {
				res({ sections })
			})
		} catch (e: any) {
			rej(e)
		}
	})

const TVvideo = ({ onError }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isMobile } = useResponsive()
	const [variants, setVariants] = useState<null | {
		sections: ChannelSectionType[]
	}>(null)

	const [formValues, setFormValues] = useState<{} | null>(null)
	const debouncedFormValues = useDebounce(formValues, 1000)
	const setFieldErrorRef = useRef()

	//Загрузка всех списков
	useEffect(() => {
		;(async () => {
			const res = await fetchAdstreamInfo()
			setVariants(res)
		})()
	}, [])

	useEffect(() => {
		if (!debouncedFormValues) return
		saveFilters(debouncedFormValues, setFieldErrorRef.current).then()
	}, [debouncedFormValues])

	function handleValidate(values) {
		const errors: any = {}
		if (values.channels.length === 0)
			errors['channels'] = 'Выберите каналы для показа'
		if (Object.keys(errors).length === 0) setFormValues(values)
		return errors
	}

	async function saveFilters(values, setFieldError) {
		const errors = await campaignInterface.saveVideoFilters(campaign?.id, {
			selection_ids: formatChannels(values),
			...values,
		})
		if (errors && setFieldError) {
			for (const [key, value] of Object.entries(errors)) {
				setFieldError(key as any, value as any)
			}
		}
	}

	function formatChannels(values) {
		return getIDFromOtherArray(
			values.channels,
			variants?.sections || [],
			'name'
		)
	}

	const initialValues = useMemo<{
		channels: string[]
		amount: string
	}>(() => {
		const c: string[] = []
		campaign.adstream.selection_ids.forEach((id) => {
			const el = variants?.sections.find((s) => s.id === id)
			if (el) c.push(el.name)
		})
		return {
			channels: c,
			amount: campaign.adstream.amount
				? parseInt(campaign.adstream.amount).toString()
				: '0',
		}
	}, [campaign.adstream, variants])

	return (
		<Formik
			initialValues={{
				channels: initialValues.channels || '',
				amount: initialValues.amount || '',
			}}
			validate={handleValidate}
			onSubmit={() => {}}
			validateOnChange={true}
			enableReinitialize
		>
			{({
				setFieldValue,
				values,
				handleSubmit,
				errors,
				setFieldError,
			}: any) => {
				setFieldErrorRef.current = setFieldError
				return (
					<Form onSubmit={handleSubmit}>
						<div
							className={'d-flex justify-content-between'}
							style={{ marginBottom: '24px' }}
						>
							<h4 className={'mb-0'}>ТВ-реклама</h4>
							<div style={isMobile ? { marginLeft: '5px' } : {}}>
								<TVIcon />
							</div>
						</div>
						<div className={'mb-3'}>
							Реклама будет транслироваться на площадке{' '}
							<a
								href="https://adstream.ru/"
								target={'_blank'}
								rel={'noreferrer'}
							>
								Adstream
							</a>
							.
						</div>
						<Form.Group className={'mb-4'}>
							<ul className={'d-flex flex-column gap-2'}>
								<li>
									Вам понадобится рекламный видеоролик. Если у
									вас его нет — мы поможем с изготовлением.
									Соответствующая опция появится на следующем
									этапе «Загрузка материалов».
								</li>
								<li>
									Зрители увидят вашу рекламу при переключении
									на один из каналов выбранной Вами категории.
									Зрители увидят только один Ваш рекламный
									ролик.
								</li>
								<li>
									Например при бюджете 50 000 ₽ на 2 недели
									вашу рекламу покажут примерно 179 000 раз
									около 12 000 потенциальных клиентов. А при
									бюджете 100 000 ₽ на 2 недели — около 357
									000 показов рекламы примерно для 25 000
									потенциальных клиентов.
								</li>
								<li>
									Укажите каналы для показа, бюджет и мы
									подготовим точный расчет вашей рекламной
									кампании на ТВ.
								</li>
							</ul>
						</Form.Group>
						<InputGroup
							hasValidation
							style={{ marginBottom: '24px' }}
						>
							<Form.Label>Каналы для показа</Form.Label>
							<AdMarketMultiplyDropdown
								list={
									variants?.sections.map((el) => el?.name) ||
									[]
								}
								selected={values.channels}
								error={errors.channels}
								onSelectedChange={(channels) => {
									setFieldError('channels', null)
									setFieldValue('channels', channels)
								}}
								additionalInfo={variants?.sections.map((el) => {
									return {
										title: el.name,
										info: GenerateChannelSectionDescription(
											el
										),
									}
								})}
								withSeparator
							/>
							<Form.Control.Feedback type="invalid">
								{errors.channels}
							</Form.Control.Feedback>
						</InputGroup>

						<Form.Group className={'mb-3'}>
							<Form.Label>Бюджет</Form.Label>
							<InputWithEndText
								type={'number'}
								className={`${isMobile ? 'w-100' : 'w-50'}`}
								value={values.amount}
								onChange={(e) => {
									setFieldError('amount', undefined)
									setFieldValue('amount', e.target.value)
								}}
								size={'lg'}
								endText={'₽'}
								isInvalid={!!errors.amount}
								FeedBackComponent={
									<Form.Control.Feedback type="invalid">
										{errors.amount}
									</Form.Control.Feedback>
								}
							/>
						</Form.Group>
					</Form>
				)
			}}
		</Formik>
	)
}

function getIDFromOtherArray(
	arr1: string[],
	arr2: any[],
	field_name_in_arr2: string
): number[] {
	const ids: number[] = []
	arr1.forEach((value) => {
		const id = arr2.find((el) => el[field_name_in_arr2] === value)?.id
		ids.push(id)
	})
	return ids
}

const channelsToDisplay = 5

function GenerateChannelSectionDescription(
	section: ChannelSectionType
): string {
	const { channels, channels_count } = section

	if (channels_count <= channelsToDisplay)
		return `${spreadArray(channels.map((ch) => ch.name))}.`
	return `${spreadArray(
		channels.slice(0, channelsToDisplay).map((ch) => ch.name)
	)} + еще ${channels_count - channelsToDisplay} других.`
}

export default TVvideo
