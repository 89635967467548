export const TOOLTIPS = {
	STEP_0: {
		BUISNESS_NAME:
			'Название можно поменять в любой момент. Это удобно, если у вас есть несколько компаний',
		INDUSTRY_TYPE:
			'Выберите вид деятельности вашей компании из списка или нажмите "Другое"',
		INN: 'Система сама найдет вашу компанию в базе',
		LOCATION:
			'Это поле важно, если вы хотите использовать наружную рекламу при создании кампании',
	},
	STEP_1: {
		RK_NAME:
			'Название кампании, которое увидят рекламные операторы при поступлении заявки к ним',
		DURATION: 'Минимальный срок размещения - две недели',
		AGE: 'Тултип для возраста',
		REGION: 'Тултип для региона'
	},
	STEP_2: {},
}
