import React, { useMemo } from 'react'
import { Form } from 'react-bootstrap'

const InputWithEndText: React.FC<any> = ({
	endText,
	FeedBackComponent,
	...props
}) => {
	const currentLength = useMemo(() => {
		return props?.value.toString().length
	}, [props.value])
	return (
		<div style={{ position: 'relative' }}>
			<Form.Control size="lg" {...props} />
			{FeedBackComponent && FeedBackComponent}
			<span
				style={{
					position: 'absolute',
					fontSize: '1.25rem',
					top: 9,
					left: 30 + currentLength * 11,
					pointerEvents: 'none',
					whiteSpace: 'nowrap',
				}}
				className={'text-secondary'}
			>
				{endText}
			</span>
		</div>
	)
}

export default InputWithEndText
