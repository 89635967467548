import React, { useEffect, useMemo, useRef } from 'react'
import {
	getInitialFromCampaign,
	getNextPeriodDate,
	getStartDatePresets,
} from './CampaignUtilsFunctions'
import moment from 'moment/moment'
import DateInput, {
	normalizeMomentDate,
} from '../../../UtilityComponents/DropdownDatePicker'
import { Alert, Button, ButtonGroup, Form } from 'react-bootstrap'
import ModalDateInput from '../../../UtilityComponents/ModalDateInput'
import { PERIODS } from './CampaignDataForm'

function StartDatePicker({
	values,
	setFieldValue,
	cn,
	errors,
	firstAvaliableDate,
	isMobile,
	campaign,
}) {
	const firstInit = useRef(true)

	useEffect(() => {
		const periodData =
			values.periodPreset === 'Другой'
				? {
						month: +values.period.month,
						weeks: values.period.plusWeeks ? 2 : 0,
				  }
				: PERIODS[values.periodPreset]

		const nexPeriodDate = getNextPeriodDate(
			moment(values.start_date),
			periodData
		)
		setFieldValue('end_date', normalizeMomentDate(nexPeriodDate))
	}, [values.start_date, values.periodPreset, values.period]) // eslint-disable-line react-hooks/exhaustive-deps
	const firstAvailableStartDate = useMemo(() => {
		const today = moment()
		if (today.date() < 20) {
			today.add(1, 'month').date(1)
		} else {
			today.add(1, 'month').date(15)
		}
		return today
	}, [])
	const AlertInvalidDateFromCampaign = useMemo(() => {
		if (!campaign?.start_date) return false
		if (
			campaign?.start_date !==
			moment(values.start_date).format('YYYY-MM-DD')
		)
			return false
		const start = moment(campaign.start_date, 'YYYY-MM-DD')
		if (
			![1, 15].includes(start.date()) ||
			firstAvailableStartDate.diff(start, 'd') > 0
		) {
			return true
		}
		return false
	}, [campaign.start_date, values.start_date]) // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<>
			<Form.Label className="d-flex">Дата начала и окончания</Form.Label>
			<ButtonGroup
				className="mb-3"
				vertical={isMobile}
				style={{ width: isMobile ? '100%' : 'inherit' }}
			>
				{values.startDatePresets.map((label, key) => (
					<Button
						key={key}
						disabled={values.startDatePreset !== label}
						variant={
							values.startDatePreset === label
								? 'primary'
								: 'secondary'
						}
						onClick={() => {
							setFieldValue('startDatePreset', label)
							const regExRes =
								/(\d{2}.\d{2}.\d{2}) - (\d{2}.\d{2}.\d{2})/g.exec(
									label
								)
							setFieldValue(
								'start_date',
								moment(regExRes?.[1], 'DD.MM.YY').toDate()
							)
						}}
					>
						{label}
					</Button>
				))}
			</ButtonGroup>
			{values.startDatePreset === 'Другая' && (
				<div className={cn('dates')}>
					{isMobile ? (
						<ModalDateInput
							firstAvaliableDate={firstAvaliableDate}
							className="me-3"
							title={'Другая дата'}
							value={moment(values.start_date).format(
								'DD.MM.YYYY'
							)}
							onChange={(date) => {
								setFieldValue('start_date', date)
							}}
							error={errors.start_date}
						/>
					) : (
						<DateInput
							firstAvaliableDate={firstAvaliableDate}
							className="me-3"
							title={'Другая дата'}
							value={moment(values.start_date).format(
								'DD.MM.YYYY'
							)}
							onChange={(date) => {
								setFieldValue('start_date', date)
							}}
							error={
								errors.start_date ||
								AlertInvalidDateFromCampaign
							}
						/>
					)}

					<DateInput
						firstAvaliableDate={firstAvaliableDate}
						disabled={true}
						value={moment(values.end_date).format('DD.MM.YYYY')}
						onChange={(date) => setFieldValue('end_date', date)}
						error={errors.end_date}
					/>
				</div>
			)}
			{AlertInvalidDateFromCampaign && (
				<Alert variant={'danger'}>
					С выбора даты создания рекламной кампании прошло слишком
					много времени. Пожалуйста, выберите другие даты.
				</Alert>
			)}
		</>
	)
}

export default StartDatePicker
