import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import useCampaignData from '../../../../stores/campaignData'
import AdFormatSelector from './AdFormatSelector'
import { Button, Card } from 'react-bootstrap'
import { AnimatePresence, motion } from 'framer-motion'
import s from './ADFormatSummary.module.scss'
import du from '../Step3-Upload/DataUpload.module.scss'
import useResponsive from '../../../../utils/useResponsive'
import BookingAD from './BookingsAD/BookingAD'
import InternetSelector from './InternetAD/InternetSelector'
import Budget from './Budget'
import TVSelector from './TVAd/TVSelector'
import { ToastsContext } from '../../../UtilityComponents/ToastsContextProvider'
import YandexDirectAd from './InternetAD/YandexDirectAd'
import VkAd from './InternetAD/VkAd'
import TVvideo from './TVAd/TVvideo'
import TVbanner from './TVAd/TVbanner'
import ReactDOM from 'react-dom'

const DataUploadSteps = [
	{ label: 'Наружная реклама' },
	{ label: 'Внутренняя реклама' },
	{ label: 'Интернет-реклама' },
	{ label: 'ТВ-реклама' },
]

const AdFormatSummary = ({ cn, onBack, onNextStep }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [panelShowing, setPanelShowing] = useState(true)
	const actionsPanelRef = useRef<HTMLDivElement | null>(null)
	const budgRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		const handleIntersection = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries
			if (!entry.isIntersecting) {
				setPanelShowing(true)
			} else {
				setPanelShowing(false)
			}
		}

		const actionsPanelObserver = new IntersectionObserver(
			handleIntersection,
			{
				rootMargin: '-100px 0px 0px 0px',
			}
		)

		const setObserver = () => {
			if (actionsPanelRef.current && budgRef.current) {
				actionsPanelObserver.observe(budgRef.current)
			} else {
				setTimeout(setObserver, 100)
			}
		}

		setObserver()

		return () => {
			actionsPanelObserver.disconnect()
		}
	}, [])

	const { isMobile } = useResponsive()
	const [adFormats, setAdFormats] = useState<any>(() => [
		{
			title: 'Наружная реклама',
			isSelected: true,
			Component: BookingAD,
			key: 'outdoor',
			data: [],
		},
		{
			title: 'Внутренняя реклама',
			isSelected: true,
			Component: BookingAD,
			key: 'indoor',
			data: [],
		},
		{
			title: 'Интернет-реклама',
			isSelected: true,
			key: 'internet',
			Component: InternetSelector,
			data: [
				{
					key: 'yandex',
					isSelected: true,
					title: 'Яндекс',
					description:
						'Авито, Mail.ru, Яндекс.Новости, Яндекс.Дзен, Яндекс.Спорт, Gismeteo и другие',
					disabled: false,
					Component: YandexDirectAd,
				},
				{
					key: 'vk',
					isSelected: true,
					title: 'VK',
					description:
						'ВКонтакте, Одноклассники, Юла, Mail.ru, MyTarget',
					disabled: false,
					Component: VkAd,
				},
			],
		},
		{
			title: 'ТВ-реклама',
			isSelected: true,
			key: 'tv',
			Component: BookingAD,
			data: [
				{
					key: 'video',
					isSelected: campaign?.adstream?.is_selected || false,
					title: 'Показ видеоролика при переключении канала',
					disabled: false,
					Component: TVvideo,
				},
				{
					key: 'banner',
					isSelected: campaign?.teletarget?.is_selected || false,
					title: 'Показ всплывающего баннера при просмотре канала',
					disabled: false,
					Component: TVbanner,
				},
			],
		},
	])
	const [stack, setStack] = useState<{ Component: any; key: string }[]>(
		() => {
			const stack: { Component: any; key: string }[] = []
			if (
				campaign?.teletarget?.is_selected ||
				campaign?.adstream?.is_selected
			) {
				stack.push({
					key: 'tv',
					Component: TVSelector,
				})
				if (campaign.teletarget.is_selected) {
					stack.push({
						key: 'banner',
						Component: TVbanner,
					})
				}
				if (campaign.adstream.is_selected) {
					stack.push({
						key: 'video',
						Component: TVvideo,
					})
				}
			}

			if (
				campaign?.internet_vk?.is_selected ||
				campaign.internet_yandex.is_selected
			) {
				stack.push({
					key: 'internet',
					Component: InternetSelector,
				})
				if (campaign.internet_yandex.is_selected)
					stack.push({
						key: 'yandex',
						Component: YandexDirectAd,
					})
				if (campaign.internet_vk.is_selected)
					stack.push({
						key: 'vk',
						Component: VkAd,
					})
			}
			if (campaign?.indoor_adv_is_selected)
				stack.push({
					key: 'indoor',
					Component: BookingAD,
				})
			if (campaign?.outdoor_adv_is_selected)
				stack.push({
					key: 'outdoor',
					Component: BookingAD,
				})
			return stack
		}
	)
	const internetFormats = useMemo(() => {
		if (!adFormats) return []
		return adFormats.find((el) => el.key === 'internet')?.data
	}, [adFormats])
	const tvFormats = useMemo(() => {
		if (!adFormats) return []
		return adFormats.find((el) => el.key === 'tv')?.data
	}, [adFormats])

	useEffect(() => {
		document.body.style.overflowY = 'overlay'
		return () => {
			document.body.style.overflowY = ''
		}
	}, [])

	const onSubmit = async () => {
		campaignInterface.patchCampaign({
			outdoor_adv_is_selected: true,
			indoor_adv_is_selected: true,
			teletarget: {
				...campaign.teletarget,
				is_selected: true,
			},
			internet_yandex: {
				...campaign.internet_yandex,
				is_selected: adFormats[2].data[0].isSelected,
			},
			internet_vk: {
				...campaign.internet_vk,
				is_selected: adFormats[2].data[1].isSelected,
			},
		})
		onNextStep()
	}

	const DataUploadStepsOnlySelected = useMemo(() => {
		return DataUploadSteps.filter((step: any) => {
			if (step.label === 'Наружная реклама')
				return { ...step }
			if (step.label === 'Внутренняя реклама')
				return { ...step }
			if (step.label === 'Интернет-реклама')
				return { ...step }
			if (step.label === 'ТВ-реклама')
				return { ...step }
			return null
		})
	}, [])

	const RenderSubMenu = ({ step, setStep, DataUploadStepsOnlySelected }) => {
		const { isMobile, isTablet } = useResponsive()
		const [, updateState] = React.useState<any>()
		const forceUpdate = React.useCallback(() => updateState({}), [])
		useEffect(() => {
			forceUpdate()
		}, []) // eslint-disable-line react-hooks/exhaustive-deps
		const SubMenuDesktop = () => (
			<div className={du.submenu}>
				{DataUploadStepsOnlySelected.map(({ label }, i) => (
					<div
						className={du.submenuItem}
						data-active={'active'}
						key={label}
						onClick={() => (i <= step ? setStep(i) : null)}
						style={{ cursor: i <= step ? 'pointer' : 'default' }}
					>
						<div className={du.circle} />
						<div className={du.label}>{label}</div>
					</div>
				))}
			</div>
		)
		const SubMenuTablet = () => (
			<div className={du.submenuTablet}>
				{DataUploadStepsOnlySelected.map(({ label }, i) => (
					<div
						className={du.submenuItemTablet}
						onClick={() => (i <= step ? setStep(i) : null)}
						style={{ cursor: i <= step ? 'pointer' : 'default' }}
						data-active={i <= step ? 'active' : ''}
						key={label}
					>
						<div className={du.circle} />
						<div className={du.label}>{label}</div>
					</div>
				))}
			</div>
		)
		return ReactDOM.createPortal(
			<>
				{/*TODO Придумать другой вариант отображения для мобилки, сейчас не влезает все* */}
				{/*{isMobile && <SubMenuMobile />}*/}
				{isTablet && !isMobile && <SubMenuTablet />}
				{!isMobile && !isTablet && <SubMenuDesktop />}
			</>,
			document.querySelector('.create-campaign-page__stepper-block')
		)
	}

	return (
		<div className={s.container}>
			
				<RenderSubMenu
					step={0}
					setStep={() => {}}
					DataUploadStepsOnlySelected={DataUploadStepsOnlySelected}
				/>
			

			<h1>Создание рекламной кампании</h1>

			{adFormats[0].isSelected ? (
				<Card className={cn('form')}>
					<BookingAD
						title="Наружная реклама"
						bookings_type="outdoor"
					/>
				</Card>
			) : null}

			{adFormats[1].isSelected ? (
				<Card className={cn('form')}>
					<BookingAD
						title="Внутренняя реклама"
						bookings_type="indoor"
					/>
				</Card>
			) : null}

			{adFormats[2].isSelected ? (
				<Card className={cn('form')}>
					<InternetSelector
						removeFromStack={(k) => {}}
						setFormats={(key) => {}}
						onError={() => {}}
						addToStack={() => {}}
						formats={internetFormats}
					/>
				</Card>
			) : null}

			{adFormats[3].isSelected ? (
				<Card className={cn('form')}>
					<BookingAD title="TV-реклама" bookings_type="tv" />
				</Card>
			) : null}

			<motion.div className={s.controls}>
				<Button
					variant={'primary'}
					style={
						isMobile ? { width: '100%', marginLeft: '10px' } : {}
					}
					disabled={
						!adFormats[3].isSelected &&
						!adFormats[2].isSelected &&
						!adFormats[1].isSelected &&
						!adFormats[0].isSelected
					}
					onClick={onSubmit}
				>
					Далее
				</Button>
				<Button variant={'secondary'} onClick={onBack}>
					Назад
				</Button>
			</motion.div>
		</div>
	)
}

export default AdFormatSummary
