import React, { FC, useMemo, useState } from 'react'
import { Formik } from 'formik'
import { YandexIcon } from './InternetSelector'
import { Col, Form, Row } from 'react-bootstrap'
import useCampaignData from '../../../../../stores/campaignData'
import InputWithEndText from '../../../../UtilityComponents/InputWithEndText'
import { addHttps, checkInternetURL, removeHttps } from './URLutils'

interface IYandexDirectAd {
	onError: (error: any) => void
	children?: React.ReactNode | React.ReactNode[]
}
type YandexDirectForm = {
	link: string
	amount: string
}

const YandexDirectAd: FC<IYandexDirectAd> = ({ onError }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [displayAll, setDisplayAll] = useState(true)
	const initialValues = useMemo<YandexDirectForm>(() => {
		const { internet_yandex: yandex } = campaign
		return {
			link: yandex.link ? removeHttps(yandex.link) : '',
			amount: yandex.amount?.toString() || '',
		}
	}, [campaign])

	const patchSingleField = async (
		field: keyof YandexDirectForm,
		value: string | number,
		setFieldError?: (field: keyof YandexDirectForm, error: string) => void
	) => {
		if (field === 'link') value = addHttps(value as string)
		if (value == campaign.internet_yandex[field]) return
		const formData = new FormData()
		formData.append(field, value.toString())
		try {
			await campaignInterface.patchInternetYandex(true)
		} catch (e) {
			const error = (e as any)?.data
			if (error && setFieldError) {
				for (const [key, value] of Object.entries(error)) {
					setFieldError(key as any, value as any)
				}
			}
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={() => {}}
			validate={() => {}}
			validateOnChange={false}
			enableReinitialize
		>
			{({
				values,
				setFieldValue,
				setFieldError,
				errors,
				handleSubmit,
			}) => {
				onError(errors)
				return (
					<Form
						onSubmit={handleSubmit}
						className={'d-flex flex-column gap-4'}
					>
						<div className={'d-flex justify-content-between'}>
							<h5 className={'mb-0'}>Яндекс</h5>
							<div>
								<YandexIcon />
							</div>
						</div>
						<Form.Group>
							<ul className={'d-flex flex-column gap-2'}>
								<li>
									Вам понадобится рекламное изображение. Если
									у вас его нет — мы поможем с изготовлением.
									Соответствующая опция появится на следующем
									этапе «Загрузка материалов».
								</li>
								<li>
									{' '}
									Аудитория увидит вашу рекламу на площадках
									Яндекс. К площадкам Яндекс относятся такие
									сайты как Авито, Gismeteo, Яндекс. Дзен и
									многие другие. Полный список площадок, вы
									можете посмотреть в специальном разделе на
									сайте{' '}
									<a
										href="https://yandex.ru/support/direct/general/yan.html"
										target={'_blank'}
										rel={'noreferrer'}
									>
										Яндекс.
									</a>
								</li>
								<li>
									{' '}
									Например при бюджете 5 000 ₽ на 2 недели
									вашу рекламу покажут примерно 145 000 раз
									около 45 000 потенциальным клиентам.{' '}
								</li>
								<li>
									{' '}
									Укажите свой бюджет и мы подготовим точный
									расчет вашей рекламной кампании в Яндексе.
								</li>
							</ul>
						</Form.Group>
						<Form.Group>
							<Form.Label>Рекламируемый сайт</Form.Label>
							<Form.Control
								type={'text'}
								size={'lg'}
								value={values.link}
								onChange={(e) => {
									setFieldError('link', undefined)
									setFieldValue('link', e.target.value)
								}}
								onBlur={() => {
									if (checkInternetURL(values.link)) {
										if (!displayAll) setDisplayAll(true)
										patchSingleField(
											'link',
											values.link,
											setFieldError
										).then()
									} else {
										setFieldError(
											'link',
											'Введите корректный адрес сайта'
										)
									}
								}}
								isInvalid={!!errors.link}
								placeholder={'Введите ссылку на сайт'}
							/>
							<Form.Control.Feedback type={'invalid'}>
								{errors.link}
							</Form.Control.Feedback>
						</Form.Group>
						{displayAll && (
							<>
								<Row>
									<Form.Group as={Col}>
										<Form.Label>Бюджет кампании</Form.Label>
										<InputWithEndText
											endText={'₽'}
											type={'number'}
											size={'lg'}
											value={values.amount}
											onChange={(e) => {
												setFieldError(
													'amount',
													undefined
												)
												setFieldValue(
													'amount',
													e.target.value
												)
											}}
											onBlur={(e) => {
												e.preventDefault()
												if (values.amount !== '') {
													patchSingleField(
														'amount',
														values.amount,
														setFieldError
													).then()
												} else {
													setFieldError(
														'amount',
														'Введите бюджет'
													)
												}
											}}
											isInvalid={!!errors.amount}
											FeedBackComponent={
												<Form.Control.Feedback
													type={'invalid'}
												>
													{errors.amount}
												</Form.Control.Feedback>
											}
										/>
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>Оплата</Form.Label>
										<Form.Control
											type={'text'}
											value={'За клики'}
											disabled
											size={'lg'}
										/>
									</Form.Group>
								</Row>
							</>
						)}
					</Form>
				)
			}}
		</Formik>
	)
}

export default YandexDirectAd
