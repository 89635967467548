import React, { useCallback, useMemo, useState } from 'react'
import { Alert, Button, Form, InputGroup, Spinner } from 'react-bootstrap'
import { Formik } from 'formik'
import useCampaignData from '../../../../stores/campaignData'
import moment from 'moment'
import useResponsive from '../../../../utils/useResponsive'
import { ConnectedFocusError } from 'focus-formik-error'
import { useHistory } from 'react-router-dom'
import FormLabelTooltip from '../../../UtilityComponents/FormLabelTooltip'
import { TOOLTIPS } from '../tooltips.data'
import {
	getInitialFromCampaign,
	getStartDatePresets,
} from './CampaignUtilsFunctions'
import StartDatePicker from './StartDatePicker'
import PeriodPicker from './PeriodPicker'
import RegionSelector from './RegionSelector'
import WatchTimeSelector from './WatchTimeSelector'
import AgeSelector from './AgeSelector'
import GenderSelector from './GenderSelector'
import { COMPANY_DATA } from 'src/mock'

export const PERIODS = {
	'2 недели': { month: 0, weeks: 2 },
	'1 месяц': { month: 1, weeks: 0 },
	'2 месяца': { month: 2, weeks: 0 },
	Другой: { month: 'other', weeks: 'other' },
}

export default function CampaignDataForm({
	cn,
	onBack,
	onNextStep,
	initialValues,
	...props
}) {
	const companyId = COMPANY_DATA[0].id
	const [campaign, campaignInterface] = useCampaignData()
	const history = useHistory()
	const { isMobile } = useResponsive()

	const initialPresets = useMemo(() => {
		return getStartDatePresets(
			Object.values(PERIODS)[0]
		)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
	// @ts-ignore

	const [initialStartDate, initialEndDate] = useMemo(() => {
		const regExRes = /(\d{2}.\d{2}.\d{2}) - (\d{2}.\d{2}.\d{2})/g.exec(
			initialPresets[0]
		)
		return [
			moment(regExRes?.[1], 'DD.MM.YY').toDate(),
			moment(regExRes?.[2], 'DD.MM.YY').toDate(),
		]
	}, [campaign]) // eslint-disable-line react-hooks/exhaustive-deps
	const [loading, setLoading] = useState(false)

	const firstAvailableDate = useMemo(() => {
		const today = moment()
		if (today.date() < 20) {
			today.add(1, 'month').date(1)
		} else {
			today.add(1, 'month').date(15)
		}
		return today
	}, [])

	async function handleSubmit(values) {
		setLoading(true)
		try {
			await campaignInterface.patchCampaign()
		} catch (e) {
			console.log('-> e', e)
		} finally {
			onNextStep()
		}
	}

	function handleValidate(values) {
		const errors: any = {}
		Object.entries(values).forEach(([key, value]) => {
			if (!value) {
				errors[key] = 'Обязательное поле'
			}
		})
		if (values.periodPreset === 'Другой' && values.period.month === '')
			errors['period'] = 'Обязательное поле'
		const momentStart = moment(values.start_date)
		if (
			![1, 15].includes(momentStart.date()) ||
			firstAvailableDate.diff(momentStart, 'd') > 0
		) {
			errors['start_date'] = 'Указана недопустимая дата'
		}
		//Check Time
		if (!values.end_time) {
			errors['end_time'] = 'Введите время'
		}
		if (!values.start_time) {
			errors['start_time'] = 'Введите время'
		}
		;['start_time', 'end_time'].forEach((key) => {
			const val = values[key]
			if (!val) {
				errors[key] = 'Введите время'
				return
			}
			if (Number(val) < 0 || Number(val) > 24) {
				errors[key] = 'Введите корректное время'
				return
			}
		})
		if (values.ages.length === 0) {
			errors['ages'] = 'Выберите хотя бы один диапозон возраста'
		}
		if (values.regions.length === 0) {
			errors['regions'] = 'Выберите хотя бы один регион'
		}

		return errors
	}

	return (
		<Formik
			initialValues={{
				name: 'МОЯ РЕКЛАМА',
				periodPreset:
					Object.keys(PERIODS)[0],
				period: getInitialFromCampaign(campaign, 'duration')
					? { ...getInitialFromCampaign(campaign, 'getmonths') }
					: { month: '', plusWeeks: false },
				startDatePresets: initialPresets,
				startDatePreset: initialPresets[0],
				start_date: initialStartDate,
				end_date: initialEndDate,
				//New Fields
				ages: campaign?.ages?.map((g) => g.id) || [12],
				genders: campaign?.genders?.map((g) => g.id) || [2],
				regions: campaign?.regions?.map((r) => r.id) || [1535],
				start_time: campaign?.start_time?.split(':')[0] || '09',
				end_time: campaign?.end_time?.split(':')[0] || '18',
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
			validate={handleValidate}
			validateOnChange={false}
		>
			{({
				setFieldValue,
				values,
				handleSubmit,
				errors,
				setFieldError,
			}: any) => (
				<Form onSubmit={handleSubmit}>
					<ConnectedFocusError />
					<InputGroup hasValidation>
						<Form.Label>
							Название{' '}
							<FormLabelTooltip text={TOOLTIPS.STEP_1.RK_NAME} />
						</Form.Label>
						<Form.Control
							disabled={true}
							size="lg"
							type={'text'}
							value={values.name}
							onChange={(e) => {
								setFieldValue('name', e.target.value)
								setFieldError('name', '')
							}}
							isInvalid={!!errors.name}
							name={'name'}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.name}
						</Form.Control.Feedback>
					</InputGroup>
					<Alert variant={'secondary'} className={'d-flex'}>
						<i className="bi bi-info-circle me-3 fw-bold" />
						Дата запуска кампании зависит от соответствия рекламных материалов техническим требованиям и времени на модерацию
					</Alert>
					<PeriodPicker
						values={values}
						setFieldValue={setFieldValue}
						isMobile={isMobile}
						errors={errors}
					/>
					<StartDatePicker
						firstAvaliableDate={firstAvailableDate}
						cn={cn}
						values={values}
						setFieldValue={setFieldValue}
						errors={errors}
						isMobile={isMobile}
						campaign={campaign}
					/>
					<RegionSelector
						value={values.regions}
						setFieldValue={setFieldValue}
						setFieldError={setFieldError}
						errors={errors.regions}
					/>

					<WatchTimeSelector
						end_time={values.end_time}
						start_time={values.start_time}
						errors={errors}
						setFieldError={setFieldError}
						setFieldValue={setFieldValue}
					/>
					<GenderSelector
						value={values.genders}
						setFieldValue={setFieldValue}
						setFieldError={setFieldError}
						error={errors.genders}
					/>
					<AgeSelector
						value={values.ages}
						setFieldValue={setFieldValue}
						setFieldError={setFieldError}
						error={errors.ages}
					/>

					{/*Navigation Buttons*/}
					<div className={cn('buttons')}>
						<Button
							variant={'primary'}
							onClick={handleSubmit}
							disabled={loading}
							style={
								isMobile
									? { width: '100%', marginLeft: '10px' }
									: {}
							}
						>
							{loading ? (
								<Spinner
									animation="border"
									variant={'light'}
									size="sm"
								/>
							) : (
								'Далее'
							)}
						</Button>
						<Button
							variant={'secondary'}
							onClick={onBack}
							disabled={true}
						>
							Назад
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	)
}
