import React, { FC } from 'react'
import { Form } from 'react-bootstrap'
import TwoValuesRange from '../../../UtilityComponents/TwoValuesRange/TwoValuesRange'

interface IWatchTimeSelector {
	start_time: string
	end_time: string
	setFieldValue: (name: string, value: string) => null
	setFieldError: (name: string, error: any) => null
	errors: {}
}

const WatchTimeSelector: FC<IWatchTimeSelector> = ({
	start_time,
	end_time,
	errors,
	setFieldError,
	setFieldValue,
}) => {
	return (
		<Form.Group className={'mt-4'}>
			<Form.Label style={{ marginBottom: '4px' }}>
				Время показа рекламы
			</Form.Label>
			<span className={'text-secondary d-block mb-3'}>
				Укажите часы (без минут) по московскому времени (GMT+3)
			</span>
			<div className={'d-flex gap-3'}>
				<span className={'mt-2'}>с</span>
				<Form.Group>
					<Form.Control
						disabled={true}
						size="lg"
						type={'number'}
						isInvalid={errors['start_time']}
						value={start_time}
						onChange={(e) => {
							if (e.target.value.length > 2) return

							setFieldError('start_time', null)
							setFieldValue('start_time', e.target.value)
						}}
						min={0}
						max={24}
						maxLength={2}
						pattern="[0-9]"
						step="1"
					/>
					<Form.Control.Feedback type={'invalid'}>
						{errors['start_time']}
					</Form.Control.Feedback>
				</Form.Group>

				<span className={'mt-2'}>до</span>
				<Form.Group>
					<Form.Control
						disabled={true}
						size="lg"
						type={'number'}
						isInvalid={errors['end_time']}
						value={end_time}
						onChange={(e) => {
							if (e.target.value.length > 2) return
							setFieldError('end_time', null)
							setFieldValue('end_time', e.target.value)
						}}
						min={0}
						max={24}
						maxLength={2}
						inputMode="numeric"
						step="1"
					/>
					<Form.Control.Feedback type={'invalid'}>
						{errors['end_time']}
					</Form.Control.Feedback>
				</Form.Group>
			</div>
		</Form.Group>
	)
}

export default WatchTimeSelector
