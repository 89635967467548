import { BookingState, CampaignStates, CompanyType, ForeCast, InternetState, TvState } from "src/stores/ADMarketTypes.types"

export const USER_DATA_ME = {
    first_name: null,
    last_name: null,
    middle_name: null,
    email: null,
    phone_number: "",
    is_phone_confirmed: true,
    provider: null,
    total_spend: 0
}

export const COMPANY_DATA: CompanyType[] = [{
    "id": "97988fd9-37da-4514-a502-16db5d19df26",
    "name": "TestB",
    "industry_type": {
        "id": 8,
        "name": "Ветеринарные клиники и зоомагазины",
        "price": "735.47000000",
        "min_shows_count": 14000,
        "max_shows_count": 25733
    },
    "inn": "123465789123",
    "kpp": '',
    "legal_name": "Тестов Тест Тестович",
    "actual_location": "г Казань",
    "actual_location_lat": "55.796127",
    "actual_location_lon": "49.106414",
    "legal_short_name": '',
    "counts": {
        "draft": 0,
        "moderation": 0,
        "payment_waiting": 0,
        "active": 0,
        "completed": 0,
        "canceled": 0
    },
    "is_selfbusy": true
}]

export const COMPANY_DATA_STEP1 = {
    "id": "466c8845-36bc-498e-a74f-f15d23477cd6",
    "state": "draft",
    "start_date": "2023-10-01",
    "end_date": "2023-10-14",
    "name": "МОЯ РЕКЛАМА",
    "created_at": "2023-08-24T06:33:42.080095+03:00",
    "is_budget_confirmed": true,
    "outdoor_adv_is_selected": false,
    "indoor_adv_is_selected": false,
    "start_time": "09:00:00",
    "end_time": "18:00:00",
    "company": {
        "id": "97988fd9-37da-4514-a502-16db5d19df26",
        "name": "TestB",
        "industry_type": {
            "id": 8,
            "name": "Ветеринарные клиники и зоомагазины",
            "price": "735.47000000",
            "min_shows_count": 14000,
            "max_shows_count": 25733
        },
        "inn": "123465789123",
        "kpp": null,
        "legal_name": "Тестов Тест Тестович",
        "actual_location": "г Казань",
        "actual_location_lat": "55.796127",
        "actual_location_lon": "49.106414",
        "legal_short_name": null,
        "counts": {
            "draft": 5,
            "moderation": 0,
            "confirmed": 0,
            "payment_waiting": 0,
            "start_waiting": 0,
            "active": 0,
            "completed": 0,
            "canceled": 0
        },
        "is_selfbusy": true
    },
    "extra_data": {
        "region_titles": [
            "Москва"
        ],
        "gender_titles": [
            "Женский"
        ],
        "age_titles": [
            "25–34"
        ],
        "total_budget": 500.0
    },
    "booking": [],
    "internet_yandex": {
        "link": null,
        "heading": null,
        "additional_heading": null,
        "text": null,
        "image": null,
        "is_selected": false,
        "views_count": null,
        "state": "draft",
        "adjustment_msg": "",
        "amount": 16000,
        "id": 1115
    },
    "internet_vk": {
        "link": null,
        "heading": null,
        "additional_heading": null,
        "text": null,
        "image": null,
        "is_selected": false,
        "views_count": null,
        "state": "draft",
        "adjustment_msg": "",
        "amount": 4000,
        "id": 861,
        "button_text": ""
    },
    "media": [],
    "budget": {
        "yandex": "0.00",
        "google": "0.00",
        "internet": "0.00",
        "banner": "0.00",
        "channel": "500.00",
        "tv": "500.00",
        "booking": "0.00",
        "indoor": "0.00",
        "outdoor": "0.00",
        "total": "500.00"
    },
    "teletarget": {
        "id": 912,
        "state": "draft",
        "is_selected": false,
        "views_count": null,
        "amount": null,
        "image": null,
        "l_image": null,
        "is_small_banner": false,
        "is_l_banner": false
    },
    "adstream": {
        "id": 927,
        "state": "draft",
        "is_selected": true,
        "views_count": null,
        "amount": "500.00",
        "cpm": "840.00",
        "image": null,
        "frequency": 1,
        "selection_ids": [],
        "duration_video": 5,
        "adjustment_msg": ""
    },
    "days_duration": 14,
    "active_payments": [],
    "active_invoices": [],
    "to_payment_waiting_dt": null,
    "state_data": {},
    "sum_payed_payments": null,
    "regions": [
        {
            "id": 1535,
            "title": "Москва",
            "parent_id": 1474,
            "is_selected_visible": true
        }
    ],
    "show_regions": [
        {
            "id": 1535,
            "title": "Москва",
            "parent_id": 1474,
            "is_selected_visible": true
        }
    ],
    "genders": [
        {
            "id": 2,
            "title": "Женский"
        }
    ],
    "ages": [
        {
            "id": 12,
            "title": "25–34"
        }
    ],
    "rents": [],
    "bid_requests": {}
}

export const TREE_DATA = [
    {
        "id": 1474,
        "title": "Все",
        "children": [
            {
                "id": 1535,
                "title": "Москва"
            },
            {
                "id": 1536,
                "title": "Московская область",
                "children": [
                    {
                        "id": 1537,
                        "title": "Балашиха"
                    },
                    {
                        "id": 1538,
                        "title": "Видное"
                    },
                    {
                        "id": 1539,
                        "title": "Долгопрудный"
                    },
                    {
                        "id": 1540,
                        "title": "Домодедово"
                    },
                    {
                        "id": 1541,
                        "title": "Истра"
                    },
                    {
                        "id": 1542,
                        "title": "Коломна"
                    },
                    {
                        "id": 1543,
                        "title": "Люберцы"
                    },
                    {
                        "id": 1544,
                        "title": "Ногинск"
                    },
                    {
                        "id": 1545,
                        "title": "Одинцово"
                    },
                    {
                        "id": 1546,
                        "title": "Подольск"
                    },
                    {
                        "id": 1547,
                        "title": "Реутов"
                    },
                    {
                        "id": 1548,
                        "title": "Сергиев Посад"
                    },
                    {
                        "id": 1549,
                        "title": "Ступино"
                    },
                    {
                        "id": 1550,
                        "title": "Химки"
                    },
                    {
                        "id": 1551,
                        "title": "Чехов"
                    },
                    {
                        "id": 1552,
                        "title": "Щёлково"
                    }
                ]
            },
            {
                "id": 1616,
                "title": "Санкт-Петербург"
            },
            {
                "id": 1532,
                "title": "Ленинградская область"
            },
            {
                "id": 1475,
                "title": "Алтайский край",
                "children": [
                    {
                        "id": 1476,
                        "title": "Барнаул"
                    },
                    {
                        "id": 1477,
                        "title": "Бийск"
                    },
                    {
                        "id": 1478,
                        "title": "Рубцовск"
                    }
                ]
            },
            {
                "id": 1479,
                "title": "Амурская область",
                "children": [
                    {
                        "id": 1480,
                        "title": "Благовещенск"
                    }
                ]
            },
            {
                "id": 1481,
                "title": "Архангельская область"
            },
            {
                "id": 1482,
                "title": "Астраханская область",
                "children": [
                    {
                        "id": 1483,
                        "title": "Астрахань"
                    }
                ]
            },
            {
                "id": 1484,
                "title": "Белгородская область",
                "children": [
                    {
                        "id": 1485,
                        "title": "Белгород"
                    }
                ]
            },
            {
                "id": 1486,
                "title": "Брянская область",
                "children": [
                    {
                        "id": 1487,
                        "title": "Брянск"
                    }
                ]
            },
            {
                "id": 1488,
                "title": "Владимирская область",
                "children": [
                    {
                        "id": 1489,
                        "title": "Владимир"
                    },
                    {
                        "id": 1490,
                        "title": "Ковров"
                    },
                    {
                        "id": 1491,
                        "title": "Муром"
                    }
                ]
            },
            {
                "id": 1492,
                "title": "Волгоградская область",
                "children": [
                    {
                        "id": 1493,
                        "title": "Волгоград"
                    },
                    {
                        "id": 1494,
                        "title": "Волжский"
                    }
                ]
            },
            {
                "id": 1495,
                "title": "Вологодская область",
                "children": [
                    {
                        "id": 1496,
                        "title": "Вологда"
                    },
                    {
                        "id": 1497,
                        "title": "Череповец"
                    }
                ]
            },
            {
                "id": 1498,
                "title": "Воронежская область",
                "children": [
                    {
                        "id": 1499,
                        "title": "Воронеж"
                    }
                ]
            },
            {
                "id": 1500,
                "title": "Забайкальский край",
                "children": [
                    {
                        "id": 1501,
                        "title": "Чита"
                    }
                ]
            },
            {
                "id": 1502,
                "title": "Ивановская область",
                "children": [
                    {
                        "id": 1503,
                        "title": "Иваново"
                    }
                ]
            },
            {
                "id": 1504,
                "title": "Иркутская область",
                "children": [
                    {
                        "id": 1505,
                        "title": "Ангарск"
                    },
                    {
                        "id": 1506,
                        "title": "Братск"
                    },
                    {
                        "id": 1507,
                        "title": "Иркутск"
                    }
                ]
            },
            {
                "id": 1508,
                "title": "Кабардино-Балкарская Республика",
                "children": [
                    {
                        "id": 1509,
                        "title": "Нальчик"
                    }
                ]
            },
            {
                "id": 1510,
                "title": "Калининградская область",
                "children": [
                    {
                        "id": 1511,
                        "title": "Калининград"
                    }
                ]
            },
            {
                "id": 1512,
                "title": "Калужская область",
                "children": [
                    {
                        "id": 1513,
                        "title": "Калуга"
                    }
                ]
            },
            {
                "id": 1514,
                "title": "Камчатский край"
            },
            {
                "id": 1515,
                "title": "Кемеровская область",
                "children": [
                    {
                        "id": 1516,
                        "title": "Кемерово"
                    },
                    {
                        "id": 1517,
                        "title": "Новокузнецк"
                    },
                    {
                        "id": 1518,
                        "title": "Прокопьевск"
                    }
                ]
            },
            {
                "id": 1519,
                "title": "Кировская область"
            },
            {
                "id": 1520,
                "title": "Краснодарский край",
                "children": [
                    {
                        "id": 1521,
                        "title": "Анапа"
                    },
                    {
                        "id": 1522,
                        "title": "Армавир"
                    },
                    {
                        "id": 1523,
                        "title": "Геленджик"
                    },
                    {
                        "id": 1524,
                        "title": "Краснодар"
                    },
                    {
                        "id": 1525,
                        "title": "Новороссийск"
                    },
                    {
                        "id": 1526,
                        "title": "Сочи"
                    }
                ]
            },
            {
                "id": 1527,
                "title": "Красноярский край",
                "children": [
                    {
                        "id": 1528,
                        "title": "Красноярск"
                    }
                ]
            },
            {
                "id": 1529,
                "title": "Курганская область",
                "children": [
                    {
                        "id": 1530,
                        "title": "Курган"
                    }
                ]
            },
            {
                "id": 1531,
                "title": "Курская область"
            },
            {
                "id": 1533,
                "title": "Липецкая область",
                "children": [
                    {
                        "id": 1534,
                        "title": "Липецк"
                    }
                ]
            },
            {
                "id": 1553,
                "title": "Мурманская область",
                "children": [
                    {
                        "id": 1554,
                        "title": "Мурманск"
                    }
                ]
            },
            {
                "id": 1555,
                "title": "Нижегородская область",
                "children": [
                    {
                        "id": 1556,
                        "title": "Арзамас"
                    },
                    {
                        "id": 1557,
                        "title": "Нижний Новгород"
                    }
                ]
            },
            {
                "id": 1558,
                "title": "Новгородская область",
                "children": [
                    {
                        "id": 1559,
                        "title": "Великий Новгород"
                    }
                ]
            },
            {
                "id": 1560,
                "title": "Новосибирская область",
                "children": [
                    {
                        "id": 1561,
                        "title": "Бердск"
                    },
                    {
                        "id": 1562,
                        "title": "Новосибирск"
                    }
                ]
            },
            {
                "id": 1563,
                "title": "Омская область",
                "children": [
                    {
                        "id": 1564,
                        "title": "Омск"
                    }
                ]
            },
            {
                "id": 1565,
                "title": "Оренбургская область",
                "children": [
                    {
                        "id": 1566,
                        "title": "Оренбург"
                    }
                ]
            },
            {
                "id": 1567,
                "title": "Орловская область"
            },
            {
                "id": 1568,
                "title": "Пензенская область",
                "children": [
                    {
                        "id": 1569,
                        "title": "Пенза"
                    }
                ]
            },
            {
                "id": 1570,
                "title": "Пермский край",
                "children": [
                    {
                        "id": 1571,
                        "title": "Пермь"
                    }
                ]
            },
            {
                "id": 1572,
                "title": "Приморский край",
                "children": [
                    {
                        "id": 1573,
                        "title": "Владивосток"
                    },
                    {
                        "id": 1574,
                        "title": "Находка"
                    }
                ]
            },
            {
                "id": 1575,
                "title": "Псковская область",
                "children": [
                    {
                        "id": 1576,
                        "title": "Псков"
                    }
                ]
            },
            {
                "id": 1577,
                "title": "Республика Башкортостан",
                "children": [
                    {
                        "id": 1578,
                        "title": "Октябрьский"
                    },
                    {
                        "id": 1579,
                        "title": "Салават"
                    },
                    {
                        "id": 1580,
                        "title": "Стерлитамак"
                    },
                    {
                        "id": 1581,
                        "title": "Уфа"
                    }
                ]
            },
            {
                "id": 1582,
                "title": "Республика Бурятия"
            },
            {
                "id": 1583,
                "title": "Республика Дагестан",
                "children": [
                    {
                        "id": 1584,
                        "title": "Дербент"
                    },
                    {
                        "id": 1585,
                        "title": "Каспийск"
                    },
                    {
                        "id": 1586,
                        "title": "Махачкала"
                    },
                    {
                        "id": 1587,
                        "title": "Хасавюрт"
                    }
                ]
            },
            {
                "id": 1588,
                "title": "Республика Калмыкия",
                "children": [
                    {
                        "id": 1589,
                        "title": "Элиста"
                    }
                ]
            },
            {
                "id": 1590,
                "title": "Республика Карелия",
                "children": [
                    {
                        "id": 1591,
                        "title": "Петрозаводск"
                    }
                ]
            },
            {
                "id": 1592,
                "title": "Республика Коми",
                "children": [
                    {
                        "id": 1593,
                        "title": "Сыктывкар"
                    }
                ]
            },
            {
                "id": 1594,
                "title": "Республика Крым",
                "children": [
                    {
                        "id": 1595,
                        "title": "Симферополь"
                    },
                    {
                        "id": 1596,
                        "title": "Феодосия"
                    },
                    {
                        "id": 1597,
                        "title": "Ялта"
                    }
                ]
            },
            {
                "id": 1598,
                "title": "Республика Марий Эл",
                "children": [
                    {
                        "id": 1599,
                        "title": "Йошкар-Ола"
                    }
                ]
            },
            {
                "id": 1600,
                "title": "Республика Северная Осетия-Алания",
                "children": [
                    {
                        "id": 1601,
                        "title": "Владикавказ"
                    }
                ]
            },
            {
                "id": 1602,
                "title": "Республика Татарстан"
            },
            {
                "id": 1603,
                "title": "Республика Тыва",
                "children": [
                    {
                        "id": 1604,
                        "title": "Кызыл"
                    }
                ]
            },
            {
                "id": 1605,
                "title": "Ростовская область",
                "children": [
                    {
                        "id": 1606,
                        "title": "Волгодонск"
                    },
                    {
                        "id": 1607,
                        "title": "Новочеркасск"
                    },
                    {
                        "id": 1608,
                        "title": "Новошахтинск"
                    },
                    {
                        "id": 1609,
                        "title": "Ростов-на-Дону"
                    },
                    {
                        "id": 1610,
                        "title": "Севастополь"
                    },
                    {
                        "id": 1611,
                        "title": "Таганрог"
                    },
                    {
                        "id": 1612,
                        "title": "Шахты"
                    }
                ]
            },
            {
                "id": 1613,
                "title": "Самарская область",
                "children": [
                    {
                        "id": 1614,
                        "title": "Самара"
                    },
                    {
                        "id": 1615,
                        "title": "Тольятти"
                    }
                ]
            },
            {
                "id": 1617,
                "title": "Саратовская область",
                "children": [
                    {
                        "id": 1618,
                        "title": "Саратов"
                    }
                ]
            },
            {
                "id": 1619,
                "title": "Свердловская область",
                "children": [
                    {
                        "id": 1620,
                        "title": "Екатеринбург"
                    },
                    {
                        "id": 1621,
                        "title": "Каменск-Уральский"
                    },
                    {
                        "id": 1622,
                        "title": "Нижний Тагил"
                    }
                ]
            },
            {
                "id": 1623,
                "title": "Смоленская область",
                "children": [
                    {
                        "id": 1624,
                        "title": "Смоленск"
                    }
                ]
            },
            {
                "id": 1625,
                "title": "Ставропольский край",
                "children": [
                    {
                        "id": 1626,
                        "title": "Ессентуки"
                    },
                    {
                        "id": 1627,
                        "title": "Кисловодск"
                    },
                    {
                        "id": 1628,
                        "title": "Пятигорск"
                    },
                    {
                        "id": 1629,
                        "title": "Ставрополь"
                    }
                ]
            },
            {
                "id": 1630,
                "title": "Тамбовская область",
                "children": [
                    {
                        "id": 1631,
                        "title": "Тамбов"
                    }
                ]
            },
            {
                "id": 1632,
                "title": "Тверская область",
                "children": [
                    {
                        "id": 1633,
                        "title": "Тверь"
                    }
                ]
            },
            {
                "id": 1634,
                "title": "Томская область",
                "children": [
                    {
                        "id": 1635,
                        "title": "Северск"
                    },
                    {
                        "id": 1636,
                        "title": "Томск"
                    }
                ]
            },
            {
                "id": 1637,
                "title": "Тульская область",
                "children": [
                    {
                        "id": 1638,
                        "title": "Тула"
                    }
                ]
            },
            {
                "id": 1639,
                "title": "Тюменская область",
                "children": [
                    {
                        "id": 1640,
                        "title": "Нефтеюганск"
                    },
                    {
                        "id": 1641,
                        "title": "Ноябрьск"
                    },
                    {
                        "id": 1642,
                        "title": "Сургут"
                    },
                    {
                        "id": 1643,
                        "title": "Тюмень"
                    }
                ]
            },
            {
                "id": 1644,
                "title": "Удмуртская Республика",
                "children": [
                    {
                        "id": 1645,
                        "title": "Ижевск"
                    }
                ]
            },
            {
                "id": 1646,
                "title": "Ульяновская область",
                "children": [
                    {
                        "id": 1647,
                        "title": "Ульяновск"
                    }
                ]
            },
            {
                "id": 1648,
                "title": "Хабаровский край",
                "children": [
                    {
                        "id": 1649,
                        "title": "Комсомольск-на-Амуре"
                    },
                    {
                        "id": 1650,
                        "title": "Хабаровск"
                    }
                ]
            },
            {
                "id": 1651,
                "title": "Ханты-Мансийский автономный округ",
                "children": [
                    {
                        "id": 1666,
                        "title": "Нефтеюганск"
                    },
                    {
                        "id": 1667,
                        "title": "Сургут"
                    }
                ]
            },
            {
                "id": 1652,
                "title": "Челябинская область",
                "children": [
                    {
                        "id": 1653,
                        "title": "Златоуст"
                    },
                    {
                        "id": 1654,
                        "title": "Копейск"
                    },
                    {
                        "id": 1655,
                        "title": "Миасс"
                    },
                    {
                        "id": 1656,
                        "title": "Челябинск"
                    }
                ]
            },
            {
                "id": 1657,
                "title": "Чеченская Республика"
            },
            {
                "id": 1658,
                "title": "Чувашская Республика",
                "children": [
                    {
                        "id": 1659,
                        "title": "Чебоксары"
                    }
                ]
            },
            {
                "id": 1660,
                "title": "Якутия",
                "children": [
                    {
                        "id": 1661,
                        "title": "Якутск"
                    }
                ]
            },
            {
                "id": 1662,
                "title": "Ямало-Ненецкий автономный округ",
                "children": [
                    {
                        "id": 1668,
                        "title": "Ноябрьск"
                    }
                ]
            },
            {
                "id": 1663,
                "title": "Ярославская область",
                "children": [
                    {
                        "id": 1664,
                        "title": "Рыбинск"
                    },
                    {
                        "id": 1665,
                        "title": "Ярославль"
                    }
                ]
            }
        ]
    }
] 

export const GENDERS_DATA = [{"id":1,"title":"Мужской"},{"id":2,"title":"Женский"},{"id":3,"title":"Любой"}]

export const AGES_DATA = [
    {
        "id": 10,
        "title": "до 18 лет"
    },
    {
        "id": 11,
        "title": "18–24"
    },
    {
        "id": 12,
        "title": "25–34"
    },
    {
        "id": 13,
        "title": "35–44"
    },
    {
        "id": 14,
        "title": "45–54"
    },
    {
        "id": 15,
        "title": "от 55"
    }
]

export const FORECAST_DATA: ForeCast = {
    "id": "b4959768-2b94-47aa-865b-a728eeeb6cc3",
    "state": CampaignStates.Draft,
    "start_date": "2023-11-01",
    "end_date": "2023-11-14",
    "name": "Рекламная кампания TestB",
    "created_at": "2023-08-24T07:33:56.031740+03:00",
    "is_budget_confirmed": true,
    "outdoor_adv_is_selected": false,
    "indoor_adv_is_selected": false,
    "start_time": "09:00:00",
    "end_time": "18:00:00",
    "vk": {
        "link": "https://asd.com",
        "heading": "хз",
        "text": null,
        "image": "https://storage.yandexcloud.net/admarket-media-dev/img_billboard.jpg",
        "is_selected": true,
        "views_count": null,
        "state": InternetState.Draft,
        "adjustment_msg": "",
        "amount": 4000,
        "id": 862,
        "button_text": "signUp",
        "showing_count": 952,
        "additional_heading": null,
        "count_click": 0
    },
    "yandex": {
        "link": null,
        "heading": null,
        "text": null,
        "image": null,
        "is_selected": true,
        "views_count": null,
        "state": InternetState.Draft,
        "adjustment_msg": "",
        "amount": 8000,
        "id": 1116,
        "showing_count": 2592,
        "additional_heading": null,
        "count_click": 0
    },
    "teletarget": {
        "id": 913,
        "state": TvState.Draft,
        "is_selected": false,
        "views_count": 2635,
        "amount": "2000",
        "image": null,
        "l_image": null,
        "is_small_banner": false,
        "is_l_banner": false,
        "showing_count": 27314,
        "count_click": 0
    },
    "adstream": {
        "id": 928,
        "state": TvState.Draft,
        "is_selected": true,
        "views_count": 2635,
        "amount": "2000",
        "cpm": null,
        "frequency": 1,
        "image": '',
        "selection_ids": [],
        "adjustment_msg": "",
        "showing_count": 2635,
        "geo_list": [],
        "count_click": 0
    },
    "booking": [
        {
            placement: {
                id: 5,
                provider: {
                    id: 5,
                    name: 'provider',
                },
                placement_type: {
                    id: 5,
                    name: '',
                    image: '',
                },
                name: 'Экран на улице',
                lat: 'string',
                lon: 'string',
                description: 'string',
                image: 'string',
                ots: 'string',
                size: 'string',
                only_work_days: true,
                works_on_holidays: true,
                is_active: true,
                activation_date: 'string',
                door_type: 'outdoor',
                screen_count: 5,
                media_type: {
                    id: 'string',
                    additional_info: null,
                    name: 'string',
                    is_image: true,
                    is_video: false,
                    video_formats: [],
                    image_formats: [],
                    min_height: null,
                    max_height: null,
                    min_width: null,
                    max_width: null,
                    aspect_ratio: null,
                    color_model: null,
                    codec: null,
                    min_fps: null,
                    max_fps: null,
                    min_bitrate: null,
                    max_bitrate: null,
                    duration: null,
                    with_audio_track: false,
                    min_dpi: null,
                    max_dpi: null,
                    image_max_size: null,
                    video_max_size: null,
                    external_link: null,
                    state: 'string',
                    in_archive: true,
                    placements: [],
                },
                
            },
            id: 5,
            placement_showing_count: {
                id: 5,
                name: 'string',
                price: 'string',
                ac_days_count: 5,
                ac_price: 5,
                showing_count_per_day: 999,
            },
            state: BookingState.Draft,
            adjustment_msg: 'msg',
            is_active: true,
            booking_budget: 55296,
            available_dates: {"12": [5]},
            count_click: 10000,
	            showing_count: 12096,
        },
        {
            placement: {
                id: 5,
                provider: {
                    id: 5,
                    name: 'provider',
                },
                placement_type: {
                    id: 5,
                    name: '',
                    image: '',
                },
                name: 'Экран на супермаркете',
                lat: 'string',
                lon: 'string',
                description: 'string',
                image: 'string',
                ots: 'string',
                size: 'string',
                only_work_days: true,
                works_on_holidays: true,
                is_active: true,
                activation_date: 'string',
                door_type: 'indoor',
                screen_count: 5,
                media_type: {
                    id: 'string',
                    additional_info: null,
                    name: 'string',
                    is_image: true,
                    is_video: false,
                    video_formats: [],
                    image_formats: [],
                    min_height: null,
                    max_height: null,
                    min_width: null,
                    max_width: null,
                    aspect_ratio: null,
                    color_model: null,
                    codec: null,
                    min_fps: null,
                    max_fps: null,
                    min_bitrate: null,
                    max_bitrate: null,
                    duration: null,
                    with_audio_track: false,
                    min_dpi: null,
                    max_dpi: null,
                    image_max_size: null,
                    video_max_size: null,
                    external_link: null,
                    state: 'string',
                    in_archive: true,
                    placements: [],
                },
                
            },
            id: 5,
            placement_showing_count: {
                id: 5,
                name: 'string',
                price: 'string',
                ac_days_count: 5,
                ac_price: 5,
                showing_count_per_day: 999,
            },
            state: BookingState.Draft,
            adjustment_msg: 'msg',
            is_active: true,
            booking_budget: 2272,
            available_dates: {"12": [5]},
            count_click: 10000,
	            showing_count: 3360,
        },
    ],
    
}