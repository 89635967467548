import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AdIcon } from '../AdFormatSelector'
import { Alert, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
	PlacementShort,
	PlacementShowingCountType,
	PlacementType,
} from '../../../../../stores/ADMarketTypes.types'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'

export type PlacementWithOnlyOneShowingCount = Omit<
	PlacementType,
	'placement_showing_counts'
> & { placement_showing_count: PlacementShowingCountType }

export interface PlacementShortWithStateAndFocus extends PlacementShort {
	name: string
	focus: null | 'focus'
	state: 'selected' | 'default' | 'unactive'
	placement_detail?: PlacementWithOnlyOneShowingCount | undefined
}

const BookingADFullScrean: React.FC<{
	show: boolean
	close: () => void
}> = ({ show, close }) => {
	if (show) {
		return (
			<div
				onClick={() => close()}
				style={{
					width: '100%',
					height: '100%',
					position: 'fixed',
					top: '0',
					left: '0',
					right: '0',
					bottom: '0',
					background: 'url(/advertisingOnMap2.png)',
					backgroundSize: 'cover',
					zIndex: 9999,
					cursor: 'pointer',
				}}
			>
				<div
					style={{
						width: '100%',
						height: '100%',
						position: 'fixed',
						top: '0',
						left: '0',
						right: '0',
						bottom: '0',
						background: 'rgba(0,0,0,0.5)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: '#fff',
						fontSize: '22px',
						fontWeight: 'bold',
						textAlign: 'center'
					}}
				>
					Тут вы будете выбирать нужный адрес и экран для вашей
					рекламы
				</div>
				<div style={{ position: 'fixed', top: '13px', right: '24px' }}>
					<img src="/close-white.svg" alt="" />
				</div>
			</div>
		)
	}

	return null
}

const BookingAD: React.FC<{
	bookings_type: 'indoor' | 'outdoor' | 'tv'
	title: string
}> = ({ bookings_type, title }) => {
	const { addToast } = useContext(ToastsContext)
	const [show, setShow] = useState(false)

	function notification(text) {
		addToast({
			text: text,
			type: 'info',
		})
	}

	function sendNotification() {
		setShow(true)
	}

	return (
		<>
			<BookingADFullScrean show={show} close={() => setShow(false)} />
			<div
				className={'d-flex justify-content-between'}
				style={{ marginBottom: '8px' }}
			>
				<h4 className={'mb-0'}>{title}</h4>
				<div>
					<AdIcon name={bookings_type} /> 
				</div>
			</div>
			<div className={'mb-3'}>
				{bookings_type === 'tv' ? (
					<>
						Реклама будет транслироваться на площадке{' '}
						<a
							href="https://adstream.ru/"
							target={'_blank'}
							rel={'noreferrer'}
						>
							Adstream
						</a>
					</>
				) : (
					'Добавьте места, где бы вы хотели разместить свою рекламу'
				)}
			</div>

			{bookings_type === 'tv' ? (
				<div className="d-flex">
					<OverlayTrigger
						placement={'top'}
						overlay={
							<Tooltip id={`tooltip-top`}>
								Тут вы будете выбирать телеканал который
								подходит для вашей рекламы
							</Tooltip>
						}
					>
						<Button
							className="flex-grow-1 mt-2"
							variant={'primary'}
							disabled={false}
						>
							<i className="bi bi-plus-lg me-2" />
							Добавить размещение
						</Button>
					</OverlayTrigger>
				</div>
			) : (
				<div className="d-flex">
					<Button
						className="flex-grow-1 mt-2"
						variant={'primary'}
						onClick={() => sendNotification()}
						disabled={false}
					>
						<i className="bi bi-plus-lg me-2" />
						Добавить места
					</Button>
				</div>
			)}
		</>
	)
}

export default BookingAD
