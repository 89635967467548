import {
	Button,
	ButtonGroup,
	Col,
	Form,
	InputGroup,
	Row,
} from 'react-bootstrap'
import FormLabelTooltip from '../../../UtilityComponents/FormLabelTooltip'
import { TOOLTIPS } from '../tooltips.data'
import React from 'react'
import { PERIODS } from './CampaignDataForm'

function PeriodPicker({ values, setFieldValue, isMobile, errors }) {
	return (
		<>
			<Form.Label className="d-flex">
				Срок размещения{' '}
				<FormLabelTooltip text={TOOLTIPS.STEP_1.DURATION} />
			</Form.Label>
			{!isMobile && (
				<ButtonGroup className="mb-3" vertical={isMobile}>
					{Object.keys(PERIODS).map((name, index) => (
						<Button
							disabled={values.periodPreset !== name}
							variant={
								values.periodPreset === name
									? 'primary'
									: 'secondary'
							}
							key={index}
						>
							{name}
						</Button>
					))}
				</ButtonGroup>
			)}
			{isMobile && (
				<div>
					<ButtonGroup
						className=""
						vertical={isMobile}
						style={{ width: '100%' }}
					>
						{Object.keys(PERIODS).map((name, index) => {
							if (index === 0 || index === 1) {
								return (
									<Button
										variant={
											values.periodPreset === name
												? 'primary'
												: 'secondary'
										}
										onClick={() =>
											setFieldValue('periodPreset', name)
										}
										key={name}
										style={{borderRadius: index === 0 ? '0.5rem 0.5rem 0 0' : '0'}}
									>
										{name}
									</Button>
								)
							}
							return <></>
						})}
					</ButtonGroup>
					<ButtonGroup
						className="mb-3"
						vertical={isMobile}
						style={{ width: '100%' }}
					>
						{Object.keys(PERIODS).map((name, index) => {
							if (index === 2 || index === 3) {
								return (
									<Button
										variant={
											values.periodPreset === name
												? 'primary'
												: 'secondary'
										}
										onClick={() =>
											setFieldValue('periodPreset', name)
										}
										key={name}
										style={{borderRadius: index === 3 ? '0 0 0.5rem 0.5rem' : '0'}}
									>
										{name}
									</Button>
								)
							}
							return <></>
						})}
					</ButtonGroup>
				</div>
			)}
		</>
	)
}

export default PeriodPicker
