import React, { FC, useMemo, useState } from 'react'
import useCampaignData from '../../../../../stores/campaignData'
import { Formik } from 'formik'
import { Col, Form, Row } from 'react-bootstrap'
import { VkIcon } from './InternetSelector'
import FormLabelTooltip from '../../../../UtilityComponents/FormLabelTooltip'
import { InternetTooltips } from './InternetTooltips'
import InputWithEndText from '../../../../UtilityComponents/InputWithEndText'
import { addHttps, checkInternetURL, removeHttps } from './URLutils'

interface IVkAd {
	onError: (error: any) => void
	children?: React.ReactNode | React.ReactNode[]
}

type VKAdForm = {
	link: string
	amount: string
}

const VkAd: FC<IVkAd> = ({ onError }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [displayAll, setDisplayAll] = useState(true)
	const initialValues = useMemo<VKAdForm>(() => {
		const { internet_vk: vk } = campaign
		return {
			link: vk.link ? removeHttps(vk.link) : '',
			amount: vk.amount?.toString() || '',
		}
	}, [campaign])

	const patchSingleField = async (
		field: keyof VKAdForm,
		value: string | number,
		setFieldError?: (field: keyof VKAdForm, error: string) => void
	) => {
		if (field === 'link') value = addHttps(value as string)
		if (value === campaign.internet_vk[field]) return
		const formData = new FormData()
		formData.append(field, value.toString())
		try {
			await campaignInterface.patchInternetVk(true)
		} catch (error: any) {
			if (error && setFieldError) {
				for (const [key, value] of Object.entries(error)) {
					setFieldError(key as any, value as any)
				}
			}
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={() => {}}
			validate={() => {}}
			validateOnChange={false}
			enableReinitialize
		>
			{({
				values,
				setFieldValue,
				setFieldError,
				errors,
				handleSubmit,
			}) => {
				onError(errors)
				return (
					<Form
						onSubmit={handleSubmit}
						className={'d-flex flex-column gap-4'}
					>
						<div className={'d-flex justify-content-between'}>
							<h5 className={'mb-0'}>VK Реклама</h5>
							<div>
								<VkIcon />
							</div>
						</div>
						<Form.Group>
							<ul className={'d-flex flex-column gap-2'}>
								<li>
									Вам понадобится рекламное изображение. Если
									у вас его нет — мы поможем с изготовлением.
									Соответствующая опция появится на следующем
									этапе «Загрузка материалов».
								</li>
								<li>
									Аудитория увидит вашу рекламу на площадках
									VK. К площадкам VK относятся такие сайты как
									ВКонтакте, Одноклассники, Юла, Mail.ru,
									MyTarget и многие другие. Полный список
									площадок, вы можете посмотреть в специальном
									разделе на сайте{' '}
									<a
										href="https://target.my.com/pro/education/online-course/creating-ad-campaign/placements"
										target={'_blank'}
										rel={'noreferrer'}
									>
										VK.
									</a>
								</li>
								<li>
									Например при бюджете 5 000 ₽ на 2 недели
									вашу рекламу покажут примерно 138 000 раз
									около 39 000 потенциальных клиентов.
								</li>
								<li>
									Укажите свой бюджет и мы подготовим точный
									расчет вашей рекламной кампании в VK.
								</li>
							</ul>
						</Form.Group>
						<Form.Group>
							<Form.Label>Рекламируемый сайт</Form.Label>
							<Form.Control
								type={'text'}
								size={'lg'}
								value={values.link}
								onChange={(e) => {
									setFieldError('link', undefined)
									setFieldValue('link', e.target.value)
								}}
								onBlur={() => {
									if (checkInternetURL(values.link)) {
										patchSingleField(
											'link',
											values.link,
											setFieldError
										).then()
									} else {
										setFieldError(
											'link',
											'Введите корректный адрес сайта'
										)
									}
								}}
								isInvalid={!!errors.link}
								placeholder={'Введите ссылку на сайт'}
							/>
							<Form.Control.Feedback type={'invalid'}>
								{errors.link}
							</Form.Control.Feedback>
						</Form.Group>
						{displayAll && (
							<>
								<Form.Group>
									<Form.Label>Целевое действие</Form.Label>
									<Form.Control
										type={'text'}
										value={'Клики по рекламе'}
										disabled
										size={'lg'}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>
										Стратегия
										<FormLabelTooltip
											text={InternetTooltips.VK.strategy}
										/>
									</Form.Label>
									<Form.Control
										type={'text'}
										value={
											'Максимум показов по минимальной цене'
										}
										disabled
										size={'lg'}
									/>
								</Form.Group>
								<Row>
									<Form.Group as={Col}>
										<Form.Label>Бюджет кампании</Form.Label>
										<InputWithEndText
											endText={'₽'}
											type={'number'}
											size={'lg'}
											value={values.amount}
											onChange={(e) => {
												setFieldError(
													'amount',
													undefined
												)
												setFieldValue(
													'amount',
													e.target.value
												)
											}}
											onBlur={() => {
												if (values.amount !== '') {
													patchSingleField(
														'amount',
														values.amount,
														setFieldError
													).then()
												} else {
													setFieldError(
														'amount',
														'Введите бюджет'
													)
												}
											}}
											isInvalid={!!errors.amount}
											FeedBackComponent={
												<Form.Control.Feedback
													type={'invalid'}
												>
													{errors.amount}
												</Form.Control.Feedback>
											}
										/>
									</Form.Group>
									<Form.Group as={Col}></Form.Group>
								</Row>
							</>
						)}
					</Form>
				)
			}}
		</Formik>
	)
}

export default VkAd
