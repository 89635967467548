import React from 'react'
import s from './ADFormatSellector.module.scss'
import { Card } from 'react-bootstrap'
import useCampaignData from '../../../../stores/campaignData'

export const SelectedIcon = () => (
	<svg
		width="18"
		height="14"
		viewBox="0 0 18 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.4571 2.3107L6.95706 12.8107C6.56654 13.2012 5.93337 13.2012 5.54285 12.8107L0.542847 7.8107L1.95706 6.39648L6.24995 10.6894L16.0428 0.896484L17.4571 2.3107Z"
		/>
	</svg>
)

export const AdIcon = ({
	name,
	withCurrentColor = false,
}: {
	name: 'indoor' | 'outdoor' | 'internet' | 'tv'
	withCurrentColor?: boolean
}): JSX.Element => {
	switch (name) {
		case 'internet':
			return (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						fill={withCurrentColor ? 'currentColor' : '#212529'}
						d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM19.9381 13H15.9685C15.8812 15.0518 15.6126 17.1686 15.1634 19.3502C17.7159 18.2502 19.5815 15.8594 19.9381 13ZM12.9884 19.9395L13.0246 19.7795C13.5548 17.4343 13.8685 15.1745 13.9665 13H10.0335C10.1315 15.1745 10.4452 17.4343 10.9754 19.7795L11.0116 19.9395C11.3354 19.9795 11.6653 20 12 20C12.3347 20 12.6646 19.9795 12.9884 19.9395ZM8.83661 19.3502C6.28406 18.2502 4.41846 15.8594 4.06189 13H8.03153C8.11884 15.0518 8.38739 17.1686 8.83661 19.3502ZM19.9381 11C19.5839 8.15915 17.7401 5.78087 15.213 4.67136C15.6998 6.65869 15.9604 8.76861 15.9958 11H19.9381ZM12.9765 4.059C12.6565 4.02005 12.3306 4 12 4C11.6694 4 11.3435 4.02005 11.0235 4.059L10.9612 4.27601C10.3646 6.35356 10.0452 8.59431 10.0045 11H13.9955C13.9548 8.59431 13.6354 6.35356 13.0388 4.27601L12.9765 4.059ZM8.787 4.67136C8.30025 6.65869 8.03964 8.76861 8.00419 11H4.06189C4.41614 8.15915 6.25991 5.78087 8.787 4.67136Z"
					/>
				</svg>
			)
		case 'outdoor':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					fill="none"
					viewBox="0 0 24 24"
				>
					<path
						fillRule="evenodd"
						d="M5 13V7h14v6H5Zm6 2H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-7v4h-2v-4Z"
						clipRule="evenodd"
						fill={withCurrentColor ? 'currentColor' : '#212529'}
					/>
				</svg>
			)
		case 'tv':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					fill="none"
					viewBox="0 0 24 24"
				>
					<path
						fillRule="evenodd"
						d="M5 18a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-6v2h3v2H8v-2h3v-2H5Zm-1-3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v10Zm6.573-9.32A1 1 0 0 0 9 6.5v7a1 1 0 0 0 1.573.82l5-3.5a1 1 0 0 0 0-1.64l-5-3.5ZM13.256 10 11 11.58V8.42L13.256 10Z"
						clipRule="evenodd"
						fill={withCurrentColor ? 'currentColor' : '#212529'}
					/>
				</svg>
			)
		case 'indoor':
			return (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8 16V5H16V16H13H11H8ZM11 18H7C6.44772 18 6 17.5523 6 17V4C6 3.44772 6.44772 3 7 3H17C17.5523 3 18 3.44772 18 4V17C18 17.5523 17.5523 18 17 18H13V21H11V18Z"
						fill={withCurrentColor ? 'currentColor' : '#212529'}
					/>
				</svg>
			)

		default:
			return <></>
	}
}

const AdFormatSelector = ({
	formats,
	setFormats,
	cn,
	addToStack,
	removeFromStack,
}) => {
	const [campaign, campaignInterface] = useCampaignData()

	function clickHandle(key) {
		const tmp = formats?.find((el) => el.key === key)
		tmp.isSelected = !tmp.isSelected
		tmp?.data?.forEach((val) => {
			val.isSelected = false
		})
		tmp.isSelected
			? addToStack({ Component: tmp.Component, key: key })
			: removeFromStack(key)
		if (!tmp.isSelected && key === 'internet') {
			removeFromStack(['vk', 'yandex'])
		}
		// if (!tmp.isSelected && key === 'tv') {
		// 	removeFromStack(['video', 'banner'])
		// }
		setFormats((p) => p.map((el) => (el.key === key ? tmp : el)))

		ActionByADType(key, tmp.isSelected).then()
	}

	async function ActionByADType(key, isSelected) {
		if (key === 'outdoor') {
			await campaignInterface.patchCampaign({
				outdoor_adv_is_selected: isSelected,
			})
		}
		if (key === 'indoor') {
			await campaignInterface.patchCampaign({
				indoor_adv_is_selected: isSelected,
			})
		}
	}
	return (
		<>
			<Card className={cn('form')} style={{ zIndex: 12 }}>
				<h2 className={cn('subtitle')} style={{ marginBottom: '8px' }}>
					Форматы рекламы
				</h2>
				<div
					className={cn('separator')}
					style={{ margin: 0, padding: 0, marginBottom: '24px' }}
				/>
				<div style={{ position: 'relative', width: '100%' }}>
					<div style={{ marginBottom: '16px' }}>
						Выберите один или несколько форматов рекламы
					</div>
					<div className={s.container}>
						{formats.map((format) => (
							<div
								className={
									format?.isSelected
										? s.formatSelected
										: s.format
								}
								key={format.key}
								onClick={(e) => {
									e.preventDefault()
									clickHandle(format.key)
								}}
							>
								<div className={s.icon}>
									{format.isSelected && <SelectedIcon />}
									{!format.isSelected && (
										<AdIcon name={format.key} />
									)}
								</div>
								<span className={s.title + ' noselect'}>
									{format.title}
								</span>
							</div>
						))}
					</div>
				</div>
			</Card>
		</>
	)
}

export default AdFormatSelector
